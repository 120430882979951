html {
  padding: 0;
  margin: 0;
  background-color: #000;
}
body {
  color: #00cc00;
  background-color: #000;
  font-family: monospace;
  font-size: 18px;
  padding: 0;
  margin: 0;
}
h1,
h2,
h3 {
  font-weight: normal;
  text-align: center;
  color: #00cc00;
  margin: 0.1em 0 0 0.1em;
}
h1 {
  font-size: 42px;
}

.white {
  color: white;
}

.header,
.footer {
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-size: 0.9rem;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.score-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game-container {
  padding: 1rem;
}

.hidden {
  display: none !important;
}

.center {
  text-align: center;
}

.instructions {
  padding: 1rem;
  flex: 1;
  max-width: 500px;
}

a {
  color: #ecf0f1;
}
a:visited {
  color: #bdc3c7;
}
.button {
  background-color: #f1c40f;
  color: #000;
  padding: 10px;
  width: 100%;
  display: block;
  margin: 10px;
  text-align: center;
  font-family: inherit;
  border: none;
  font-size: inherit;
  cursor: pointer;
}
.button a {
  color: #000;
}
.button:visited {
  color: #000;
}

#leaders {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Leaderboard */
.leaders {
  border: none;
  border-collapse: collapse;
}
.leaders p {
  margin: 0;
}
.leaders td,
.leaders th {
  padding: 0.5rem;
  text-align: center;
}
.leaders-namewrapper {
  display: flex;
  align-items: center;
}
.leaders-avatar {
  border-radius: 50em;
  margin-right: 1rem;
  max-width: 30px;
}
.leaders-gamethumb {
  width: 50px;
  height: 50px;
  position: relative;
}
.leaders-gamethumb img {
  max-width: 100%;
  border: 1px solid #00cc00;
}
.leaders-gamethumb:hover img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 300px;
  max-width: initial;
}

#loadMore {
  background: none;
  color: yellow;
  text-decoration: underline;
  border: none;
  font-family: monospace;
  font-size: 1rem;
  cursor: pointer;
}
